import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "placeholder"]
  
  constructor(props){
    super(props)
    this.reader_load = this.reader_load.bind(this)
	}
	
	connect() {
		console.log("YO")
	}

  update_placeholder(event) {
    if (event.target.id === "ad_image" && event.target.files.length > 0) {
      let reader  = new FileReader()
      reader.onload = this.reader_load
      reader.readAsDataURL(event.target.files[0]);
    }
  }
  
  reader_load(e) {
    if(e.target.result.length && e.target.result !== ""){
      this.placeholderTarget.style.backgroundImage = `url(${ e.target.result })`
      this.placeholderTarget.classList.add('flex')
      this.placeholderTarget.classList.remove('hidden')
    }
  }
  
}