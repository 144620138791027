import { Controller } from "stimulus";
var stripe = null

export default class extends Controller {
	static targets = [
		"name",
		"address_line_1",
		"address_line_2",
		"country",
		"zip",
		"terms",
		"error",
		"city",
		"submit",
		"form",
		"vat_message",
		"plan"
	]

	connect() {
		if (stripe === null) {
			stripe = Stripe(process.env.STRIPE_PUBLIC_KEY)
		}
		// Let's check if form details are already filled in
		this.set_country_select()
		this.set_form_classes()
	}

	set_country_select() {
		const data_value = this.countryTarget.getAttribute('data-value')
		this.countryTarget.classList.add('text-gray-500')
		if (data_value && this.countryTarget.children) {
			for (let index = 0; index < this.countryTarget.children.length; index++) {
				const element = this.countryTarget.children[index]
				if (element.value === data_value) {
					element.selected = true
					this.countryTarget.classList.remove('text-gray-500')
					this.countryTarget.classList.add('text-black')
					this.check_vat_rate()
				}
				else {
					element.selected = false
				}
			}
		}
	}

	set_form_classes() {
		this.has_valid_form_detail ? this.enable_checkout_button() : this.disable_checkout_button()
		
		if (this.countryTarget.value !== "") {
			this.countryTarget.classList.remove('text-gray-500')
			this.countryTarget.classList.add('text-black')
		}
		else {
			this.countryTarget.classList.add('text-gray-500')
			this.countryTarget.classList.remove('text-black')
		}
	}

	check_form() {
		this.set_form_classes()
		if (this.countryTarget.value !== "") {
			// We want to get the VAT rate
			this.check_vat_rate()
		}
	}

	check_vat_rate() {
		setTimeout(() => {
			fetch(`/billing/vat_rate/${this.countryTarget.value}/${this.planTarget.value}`, {
				method: "get",
			})
			.then(response => response.text())
			.then(result => {
				const info = JSON.parse(result)
				if (info.rate !== null) {
					this.vat_messageTarget.classList.remove('hidden')
					this.vat_messageTarget.innerHTML = `${info.text} <strong>$${Number(info.price).toFixed(2)}</strong> ($${info.base_price} + $${Number(info.vat_price).toFixed(2)}).`
				}
				else {
					this.vat_messageTarget.classList.add('hidden')
					this.vat_messageTarget.innerHTML = ""
				}
			})
		}, 100)
	}

	enable_checkout_button() {
		this.submitTarget.classList.remove('opacity-50')
		this.submitTarget.classList.remove('cursor-not-allowed')
		this.submitTarget.disabled = false
	}

	disable_checkout_button() {
		this.submitTarget.classList.add('opacity-50')
		this.submitTarget.classList.add('cursor-not-allowed')
		this.submitTarget.disabled = true
	}

	launch_checkout(event) {
		event.preventDefault()
		this.disable_checkout_button()
		if (this.has_valid_form_detail) {
			const data = new URLSearchParams(new FormData(this.formTarget));
			fetch('/billing/payment_session', {
				body: data,
				method: "post",
			})
				.then(response => response.text())
				.then((result) => {
					stripe.redirectToCheckout({
						sessionId: JSON.parse(result).id
					}).then(function (result) {
						alert(result.error.message)
						
					})
				})
				.catch((error) => {
					console.log("There was an error!")
					alert("There was an error with your payment. Please try again. If it persists, try reloading the page. Alternatively, contact us.")
					this.enable_checkout_button()
				})
		}
		else {
			alert("Please fill out the form")
		}
		
	}

	get has_valid_form_detail() {
		return this.nameTarget.value !== ""
			&& this.address_line_1Target.value !== ""
			&& this.countryTarget.value !== ""
			&& this.zipTarget.value !== ""
			&& this.cityTarget.value !== ""
			&& this.termsTarget.checked
	}
	
}