import { h, Component } from 'preact'
import { LineChart } from 'react-chartkick'
import 'chart.js'

export default class ListChart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      taps: 0,
      views: 0,
      loading: true
    }
    this.tap_endpoint = null
    this.view_endpoint = null
  }

  componentDidMount = () => {
    switch(this.props.type){
      case "ad":
        this.tap_endpoint = `/stats/ad/${this.props.id}?simple=true`
        this.view_endpoint = `/stats/ad_views/${this.props.id}?simple=true`
      break;
      case "category":
        this.tap_endpoint = `/stats/category/${ this.props.id }?simple=true`
        this.view_endpoint = `/stats/category_views/${ this.props.id }?simple=true`
      break;
      case "client":
        this.tap_endpoint = `/stats/client_taps/${this.props.id}`
        this.view_endpoint = `/stats/client_views/${this.props.id}`
      break;
    }
    const saved_stats = sessionStorage.getItem(`list_stats_${this.props.type}_${this.props.id}`)
    if(saved_stats){
      this.setState(JSON.parse(saved_stats), this._get_data)
    }
    else{
      this._get_data()
    }
  }
  
  _get_data = () => {
    if(this.tap_endpoint != null){
      this.setState({loading: true}, () => {
        fetch(this.tap_endpoint).then((response) => {
          if (response.status === 200) {
            response.json().then((result) => {
              if (result.taps != null) {
                this.setState({ taps: result.taps, loading: false }, this._save_stats)
              }
            })
          }
        })
      })
    }
    if(this.view_endpoint != null){
      this.setState({loading: true}, () => {
        fetch(this.view_endpoint).then((response) => {
          if (response.status === 200) {
            response.json().then((result) => {
              if (result.views != null) {
                this.setState({ views: result.views, loading: false }, this._save_stats)
              }
            })
          }
        })
      })
    }
  }
  
  _save_stats = () => {
    sessionStorage.setItem(`list_stats_${this.props.type}_${this.props.id}`, JSON.stringify(this.state))
  }
  
  render() {
    return (
      <div class="px-3 inline-flex font-semibold text-sm rounded-full bg-blue-200 text-blue-900 flex-row">
        <span class="inline-flex py-1"><span class="font-extralight">Taps:&nbsp;</span>{this.state.taps}</span>
        <span class="inline-flex py-1 border-l ml-2 pl-2 border-blue-300"><span class="font-extralight">Views:&nbsp;</span> {this.state.views}</span>
      </div>
    )
  }
}