import { Controller } from "stimulus";

export default class extends Controller {
	static targets = ["image", "title", "content", "image_preview"]

	connect() {
	}

	update() {
	}
	
}