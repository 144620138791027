import { Controller } from "stimulus";

export default class extends Controller {
  
  static targets = ["row", "filter", "reset"]
  
  static values = {
    selectedClientId: Number
  }
  
  connect(){
    if(this.filterTarget != null){
      const existingFilterId = sessionStorage.getItem("clientFilterId")
      if(existingFilterId != null){
        this.selectedClientIdValue = Number(existingFilterId)
      }
      this.setDropdown()
      this.setSelectedRows()
    }
  }
  
  setSelectedRows() {
    this.rowTargets.forEach(row => {
      if(this.selectedClientIdValue === 0){
        row.classList.remove("hidden")
      }
      else{
        const clientId = row.getAttribute("data-clientid")
        if(clientId != null && Number(clientId) == this.selectedClientIdValue){
          row.classList.remove("hidden")
        }
        else{
          row.classList.add("hidden")
        }
      }
      
    })
  }
  
  change(e){
    const value = Number(e.target.value)
    if(value != null && value > 0){
      this.selectedClientIdValue = Number(value)
      this.resetTarget.classList.remove("hidden")
    }
    else{
      this.selectedClientIdValue = 0
      this.resetTarget.classList.add("hidden")
    }
    sessionStorage.setItem("clientFilterId", this.selectedClientIdValue)
    this.setSelectedRows()
  }
  
  reset(){
    this.selectedClientIdValue = 0
    this.resetTarget.classList.add("hidden")
    sessionStorage.removeItem("clientFilterId")
    this.setDropdown()
    this.setSelectedRows()
  }
  
  setDropdown(){
    this.filterTarget.value = this.selectedClientIdValue
    if(this.selectedClientIdValue > 0){
      this.resetTarget.classList.remove("hidden")
    }
  }

}