import { h, Component } from 'preact'
import DatePicker from "react-datepicker";
import { parseISO, addDays, subDays } from 'date-fns'
import dayjs from 'dayjs'

export default class AdChart extends Component {

  constructor(props) {
    super(props);
    this.state = {
      start_date: null,
      end_date: null
    }
    this.start_input = this.props.start_input
    this.end_input = this.props.end_input
  }

  componentDidMount = () => {
    if(this.start_input.value != null && this.start_input.value != ""){
      this.setState({start_date: parseISO(this.start_input.value)})
    }
    if(this.end_input.value != null && this.end_input.value != ""){
      this.setState({end_date: parseISO(this.end_input.value)})
    }
  }
  
  _set_start_date = (date) => {
    this.setState({start_date: date}, () => {
      this.start_input.value = date === null ? "" : dayjs(date).startOf('day').toISOString()
    })
  }
  
  _set_end_date = (date) => {
    this.setState({end_date: date}, () => {
      this.end_input.value = date === null ? "" : dayjs(date).endOf('day').toISOString()
    })
  }
  
  render() {
    return (
      <div class="mb-4 flex flex-col md:flex-row">
        <div>
          <label for="start_date_picker" class="flex font-bold mb-2">Start date:</label>
          <DatePicker
            id="start_date_picker"
            name="start_date_picker"
            title="Start date"
            placeholderText="Set a start date"
            todayButton="Today"
            dateFormat="yyyy-MM-dd"
            selected={this.state.start_date}
            onChange={(date) => this._set_start_date(date)}
            minDate={new Date()}
            maxDate={this.state.end_date !== null ? new subDays(this.state.end_date, 1) : null}
            isClearable
            showDisabledMonthNavigation
          />
        </div>
        <div class="mt-4 md:mt-0 md:ml-6">
          <label for="end_date_picker" class="flex font-bold mb-2">End date:</label>
          <DatePicker
            id="end_date_picker"
            name="end_date_picker"
            title="End date"
            placeholderText="Set an end date"
            dateFormat="yyyy-MM-dd"
            selected={this.state.end_date}
            onChange={(date) => this._set_end_date(date)}
            minDate={this.state.start_date !== null ? new addDays(this.state.start_date, 1) : new addDays(new Date(), 1)}
            isClearable
            showDisabledMonthNavigation
          />
        </div>
        
      </div>
    )
  }
}