import { Controller } from "stimulus";

export default class extends Controller {
  
	connect() {
		if (this.element != null) {
			this.element.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" });
			this.element.classList.add("transition", "bg-blue-200", "dark:bg-blue-800", "rounded-xl", "p-4", "border-dashed", "border", "border-black")
		}
	}
  
}