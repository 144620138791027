import { Controller } from "stimulus";
var stripe = null

export default class extends Controller {
	static targets = ["submit"]

	connect() {
		if (stripe === null) {
			stripe = Stripe(process.env.STRIPE_PUBLIC_KEY)
		}
	}


	enable_checkout_button() {
		this.submitTarget.classList.remove('opacity-50')
		this.submitTarget.classList.remove('cursor-not-allowed')
		this.submitTarget.disabled = false
	}

	disable_checkout_button() {
		this.submitTarget.classList.add('opacity-50')
		this.submitTarget.classList.add('cursor-not-allowed')
		this.submitTarget.disabled = true
	}

	launch_card_change(event) {
		event.preventDefault()
		this.disable_checkout_button()
		fetch('/billing/change_card_details')
			.then(response => response.text())
			.then((result) => {
				stripe.redirectToCheckout({
					sessionId: JSON.parse(result).id
				}).then(function (result) {
					alert(result.error.message)
				})
			})
			.catch((error) => {
				console.log("There was an error!")
				this.enable_checkout_button()
			})
	}
	
}