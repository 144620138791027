import { Controller } from "stimulus";

export default class extends Controller {
  static targets = ["input", "output"]
  
  change(){
    const current_color = this.inputTarget.value
    if(current_color){
      this.inputTarget.value = current_color.replace("#", "")
      this.outputTarget.style.backgroundColor = `#${current_color.replace("#", "")}`
    }
  }
  
  set_color(event){
    event.preventDefault()
    if(event.target?.value){
      this.inputTarget.value = event.target?.value.replace("#", "")
      this.outputTarget.style.backgroundColor = `#${event.target?.value.replace("#", "")}`
    }
  }
  
}