import { Controller } from "stimulus";
import { render, h } from 'preact'
import SchedulePicker from './../components/dates/schedule'

export default class extends Controller {
  static targets = ["start", "end", "input"]

  connect() {
    if (!document.documentElement.hasAttribute("data-turbolinks-preview")) {
      if (this.inputTarget !== null && this.startTarget && this.endTarget) {
        render(<SchedulePicker start_input={this.startTarget} end_input={this.endTarget} />, this.inputTarget);
      }
    }
  }
  
}